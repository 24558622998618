import React from "react"
import { Link } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from '../components/hero';
import MainBlock from '../components/main-block';
import LkpCard from '../components/LkpCard';
import DualBlock from '../components/dualBlock';
import TransactionQuery from '../components/query';
import ModuleConsumos from '../components/moduleConsumos';

import shoppingCart from '../images/icons/shopping-cart-light.svg'
import creditCard from '../images/icons/credit-card-light.svg'
import checkCircle from '../images/icons/check-circle-light.svg'

import clouds from '../images/icons/clouds-light.svg'
import lockAlt from '../images/icons/lock-alt-light.svg'
import thumbsUp from '../images/icons/thumbs-up-light.svg'
import eye from '../images/icons/eye-light.svg'


import { injectIntl } from "gatsby-plugin-intl"


const IndexPage = ({ intl }) => {
	return (
		<Layout page={'index'}>
			<SEO title="Home" />
			<Hero page={'index'} h1={intl.formatMessage({ id: "home.hero.title" })} />
			<ModuleConsumos location={'index'} />
			<MainBlock page={'index'} />

			<div className="index-cta-main-block" id="new-accounts">
				<div className="container">
					<div className="flex-gap">
						<LkpCard>
							<div className="card-type-a">
								<div className="icon"><img src={shoppingCart} width={50} alt="" /></div>
								<h3>{intl.formatMessage({ id: "home.derivate.pay.title" })}</h3>
								<ul>
									<li>
										<div className="icon"><img src={checkCircle} className="m-r-15" width={20} alt="" /></div>
										{intl.formatMessage({ id: "home.derivate.pay.list.l1" })}</li>
									<li>
										<div className="icon"><img src={checkCircle} className="m-r-15" width={20} alt="" /></div>
										{intl.formatMessage({ id: "home.derivate.pay.list.l2" })}</li>
									<li>
										<div className="icon"><img src={checkCircle} className="m-r-15" width={20} alt="" /></div>
										{intl.formatMessage({ id: "home.derivate.pay.list.l3" })}</li>
									<li>
										<div className="icon"><img src={checkCircle} className="m-r-15" width={20} alt="" /></div>
										{intl.formatMessage({ id: "home.derivate.pay.list.l4" })}</li>
									<li>
										<div className="icon"><img src={checkCircle} className="m-r-15" width={20} alt="" /></div>
										{intl.formatMessage({ id: "home.derivate.pay.list.l5" })}</li>
								</ul>
								<Link to="/individuos" className="btn-primary m-t-30 w-100">{intl.formatMessage({ id: "home.derivate.pay.cta" })}</Link>
							</div>
						</LkpCard>
						<LkpCard>
							<div className="card-type-a">
								<div className="icon"><img src={creditCard} width={50} alt="" /></div>
								<h3>{intl.formatMessage({ id: "home.derivate.charge.title" })}</h3>
								<ul>
									<li>
										<div className="icon"><img src={checkCircle} className="m-r-15" width={20} alt="" /></div>
										{intl.formatMessage({ id: "home.derivate.charge.list.l1" })}</li>
									<li>
										<div className="icon"><img src={checkCircle} className="m-r-15" width={20} alt="" /></div>
										{intl.formatMessage({ id: "home.derivate.charge.list.l2" })}</li>
									<li>
										<div className="icon"><img src={checkCircle} className="m-r-15" width={20} alt="" /></div>
										{intl.formatMessage({ id: "home.derivate.charge.list.l3" })}</li>
									<li>
										<div className="icon"><img src={checkCircle} className="m-r-15" width={20} alt="" /></div>
										{intl.formatMessage({ id: "home.derivate.charge.list.l4" })}</li>
									<li>
										<div className="icon"><img src={checkCircle} className="m-r-15" width={20} alt="" /></div>
										{intl.formatMessage({ id: "home.derivate.charge.list.l5" })}</li>
								</ul>
								<Link to="/empresas" className="btn-primary m-t-30 w-100">{intl.formatMessage({ id: "home.derivate.charge.cta" })}</Link>
							</div>
						</LkpCard>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="index-features-grid">
					<h2 className="text-center">{intl.formatMessage({ id: "home.advantages.title" })}</h2>
					<div className="flex-gap">
						<div className="feature-item">
							<div className="icon"><img src={clouds} height="30" alt="" /></div>
							<h4>{intl.formatMessage({ id: "home.advantages.i1.title" })}</h4>
							<p>{intl.formatMessage({ id: "home.advantages.i1.desc" })}</p>
						</div>
						<div className="feature-item">
							<div className="icon"><img src={lockAlt} height="30" alt="" /></div>
							<h4>{intl.formatMessage({ id: "home.advantages.i2.title" })}</h4>
							<p>{intl.formatMessage({ id: "home.advantages.i2.desc" })}</p>
						</div>
						<div className="feature-item">
							<div className="icon"><img src={thumbsUp} height="30" alt="" /></div>
							<h4>{intl.formatMessage({ id: "home.advantages.i3.title" })}</h4>
							<p>{intl.formatMessage({ id: "home.advantages.i3.desc" })}</p>
						</div>
						<div className="feature-item">
							<div className="icon"><img src={eye} height="30" alt="" /></div>
							<h4>{intl.formatMessage({ id: "home.advantages.i4.title" })}</h4>
							<p>{intl.formatMessage({ id: "home.advantages.i4.desc" })}</p>
						</div>

					</div>
				</div>
			</div>

			{/* --------- TOP ---------- */}
			<div className="border-bg-top"></div>
			{/* --------- CONTENT HEXAGONS ---------- */}
			<div className="index-steps-how-it-works">
				<div className="title-container m-b-100">
					<h2 className="text-white">Cómo funciona</h2>
				</div>
				<div className="container">
					<div className="hex">
						<div className="hex-content">
							<span className="number">01</span>
							<div className="desc">{intl.formatMessage({ id: "home.steps.s1" })}</div>
						</div>
					</div>
					<div className="hex">
						<div className="hex-content">
							<span className="number">02</span>
							<div className="desc">{intl.formatMessage({ id: "home.steps.s2" })}</div>
						</div>
					</div>
					<div className="hex">
						<div className="hex-content">
							<span className="number">03</span>
							<div className="desc">{intl.formatMessage({ id: "home.steps.s3" })}</div>
						</div>
					</div>
				</div>
			</div>
			{/* --------- BOTTOM ---------- */}
			<div className="border-bg-bottom"></div>

			<DualBlock page="index" />

			<TransactionQuery page="index" />

		</Layout>
	)
}

export default injectIntl(IndexPage)
